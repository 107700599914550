import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, PropsWithChildren } from 'react';
import Footer from './Footer';
import TopBar from './TopBar';

const useStyles = makeStyles({
    root: { minHeight: '100vh' },
    content: {
        backgroundColor: '#EEF3F9',
        padding: '2rem',
    },
});

interface SignedOutLayoutProps {
    linkPrefix?: string;
}

const SignedOutLayout: FC<PropsWithChildren<SignedOutLayoutProps>> = ({
    children,
    linkPrefix,
}) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.root}
        >
            <Grid item>
                <TopBar linkPrefix={linkPrefix} />
            </Grid>

            <Grid item xs className={classes.content}>
                {children}
            </Grid>

            <Grid item>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default SignedOutLayout;
