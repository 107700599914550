import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent, useRef, useState } from 'react';
import PrimaryButton from './PrimaryButton';

const useStyles = makeStyles({ buttonGroup: { boxShadow: 'none' } });

export interface SplitButtonProps {
    buttons: Array<{
        name: string;
        onClick: () => void;
    }>;
    icon?: JSX.Element;
    defaultButtonIndex?: number;
}

const SplitButton: FunctionComponent<React.PropsWithChildren<SplitButtonProps>> = ({
    buttons, icon, defaultButtonIndex,
}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(defaultButtonIndex ?? 0);

    // TODO: Translate aria labels

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup
                    variant="contained"
                    color="secondary"
                    ref={anchorRef}
                    aria-label="split button"
                    className={classes.buttonGroup}
                >
                    <PrimaryButton
                        icon={icon}
                        onClick={() => buttons[selectedIndex].onClick()}
                    >
                        {buttons[selectedIndex].name}
                    </PrimaryButton>

                    <Button
                        color={'secondary'}
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={() => setOpen(prevOpen => !prevOpen)}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>

                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener
                                    onClickAway={event => {
                                        const clickedAnchor = anchorRef.current
                                            && anchorRef.current
                                                .contains(event.target as HTMLElement);

                                        if (!clickedAnchor) {
                                            setOpen(false);
                                        }
                                    }}
                                >
                                    <MenuList id="split-button-menu">
                                        {buttons.map((button, index) => (
                                            <MenuItem
                                                key={index}
                                                disabled={index === 2}
                                                selected={index === selectedIndex}
                                                onClick={() => {
                                                    setSelectedIndex(index);
                                                    setOpen(false);
                                                }}
                                            >
                                                {button.name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
};

export default SplitButton;
