/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { formatISO } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import React, { FunctionComponent } from 'react';

import TextField from './TextField';

export interface DateFieldProps extends Partial<DatePickerProps<Date, Date>> {
    required?: boolean;
    helperText?: string;
    utc?: boolean;
    error?: boolean;
    format?: string;
    minimum?: Date;
    onChange: (newValue: Date | null) => void;
}

const DateField: FunctionComponent<React.PropsWithRef<DateFieldProps>> = ({
    required,
    helperText,
    utc,
    error,
    format,
    minimum,
    onChange,
    ...rest
}) => {
    const filteredValue = rest.value === null || (minimum && isBefore(rest.value ?? -1, minimum)) ? null : rest.value;

    return (
        <DatePicker
            {...rest}
            value={filteredValue ?? null}
            onChange={newValue => {
                const result = (() => {
                    if (newValue === null) {
                        return null;
                    }

                    return utc ? new Date(formatISO(newValue)) : newValue;
                })();

                onChange(result);
            }}
            componentsProps={{
                actionBar: { actions: ['clear'] },
            }}
            renderInput={props => (
                <TextField
                    {...props}
                    required={required}
                    helperText={helperText ?? ' '}
                    label={utc ? `${rest.label} (UTC)` : rest.label}
                    error={error}
                    variant="filled"
                />
            )}
            minDate={minimum}
            inputFormat={format ?? 'dd/MM/yyyy'}
        />
    );
};

export default DateField;
