import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

export type Severity = AlertColor;

export interface AlertCustomProps extends AlertProps {
    inline?: boolean;
    className?: string;
}

const useStyles = makeStyles({ inline: { display: 'inline-flex' } });

const Alert: FunctionComponent<React.PropsWithChildren<AlertCustomProps>> = (
    { children, inline, className, ...rest },
) => {
    const classes = useStyles();

    return (
        <MuiAlert
            {...rest}
            className={
                clsx(
                    { [classes.inline]: inline },
                    className,
                )
            }
        >
            {children}
        </MuiAlert>
    );
};

export default Alert;
