import { Typography } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import React, { ElementType, FunctionComponent } from 'react';

export interface HeadingProps {
    level: 1 | 2 | 3 | 4;
}

const Heading: FunctionComponent<React.PropsWithChildren<HeadingProps>> = ({
    children,
    level,
}) => (
    <Typography
        variant={`h${level}` as TypographyVariant}
        component={`h${level}` as ElementType}
    >
        {children}
    </Typography>
);

export default Heading;
