import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import React, { FC, useMemo, PropsWithChildren } from 'react';
import theme from './styles/theme';

type ThemeProviderType = FC<PropsWithChildren<{ seed?: string }>>;

const ThemeProvider: ThemeProviderType = ({ children, seed }) => {
    const generateClassName = useMemo(() => createGenerateClassName({ seed }), [seed]);

    return (
        <StyledEngineProvider injectFirst>
            <StylesProvider generateClassName={generateClassName}>
                <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
            </StylesProvider>
        </StyledEngineProvider>
    );
};

export default ThemeProvider;
