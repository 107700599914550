import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useDataGridStyles = makeStyles(() => createStyles({
    dataGridHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 10px',
        borderBottom: '1px solid #e0e0e0',
        '& h2': {
            margin: '0px',
        },
    },
    dataGrid: {
        width: '100%',
        minWidth: '100px',
        color: '#073690',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',

        '& .MuiDataGrid-root': {
            border: 'none',
            minHeight: '100px',
        },
    },
    dataGridNoRows: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    iconNoRows: {
        height: 70,
        width: 100,
    },
}));

export default useDataGridStyles;
