import {
    FilledTextFieldProps as MuiTextFieldProps,
    TextField as MuiTextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

export interface TextFieldProps extends Partial<MuiTextFieldProps> {
    noHelperTextSpace?: boolean;
    fixedHelperText?: boolean;
}

const useStyles = makeStyles({
    root: { marginBottom: '.25rem' },
    fixedHelperText: { marginBottom: '1rem' },
});

const TextField: FunctionComponent<React.PropsWithChildren<TextFieldProps>> = ({
    noHelperTextSpace,
    fixedHelperText,
    ...rest
}: TextFieldProps) => {
    const classes = useStyles();

    return (
        <MuiTextField
            {...rest}
            fullWidth
            helperText={rest.helperText ?? (noHelperTextSpace ? '' : ' ')}
            className={clsx(
                classes.root,
                { [classes.fixedHelperText]: fixedHelperText },
                rest.className,
            )}
            variant="filled"
        />
    );
};

export default TextField;
