/* eslint-disable max-statements */
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectClasses,
    SelectProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useState, useEffect, ReactNode } from 'react';

import { PrimaryButton } from '../buttons';
import { COLORS } from '../constants/colors';
import useRandomId from '../helpers/useRandomId';

const useStyles = makeStyles({
    button: {
        borderTop: `1px solid ${COLORS.BLACK_12}`,
        borderBottom: `1px solid ${COLORS.BLACK_12}`,
        borderLeft: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: 0,
        backgroundColor: COLORS.WHITE,
        color: COLORS.BLACK,
        '&:hover': {
            backgroundColor: COLORS.BLUE_4,
        },
    },
    buttonSelected: {
        backgroundColor: COLORS.BLUE,
        color: COLORS.WHITE,
        '&:hover': {
            backgroundColor: COLORS.BLUE_DARK,
        },
    },
    firstButton: {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
    },
    lastButton: {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderRight: `1px solid ${COLORS.BLACK_12}`,
    },
    numberedIcon: { width: '22px',
        height: '22px',
        borderRadius: 11,
        background: COLORS.LIGHT_GRAY,
        color: COLORS.DARK_GRAY,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10px',
        fontWeight: 700 },
    proba: {
        background: 'red',
    },
});

export interface MultipleSelectProps<T extends Map<string, string>> extends SelectProps {
    values: T;
    onSetIds: (values: string[]) => void;
    numberedValues?: T;
    helperText?: string;
    defaultSelectedValue?: string[];
    disabledValue?: string[];
    displayAsButtons?: boolean;
    classNames?: {
        componentWrapper?: string;
        selectWrapper?: string;
        selectClasses?: Partial<SelectClasses>;
        item?: string;
        checkbox?: string;
        text?: string;
        buttonsWrapper?: string;
        numberedIcon?: string;
    };
}

function getMapKeyByValue(values: Map<string, string>, value: string): string {
    let result = '';

    values.forEach((val, key) => {
        if (val === value) {
            result = key;
        }
    });
    return result;
}

const DEFAULT_VALUE: string[] = [];

export const NumberedIcon = ({ count, className }: {count: string; className?: string}): JSX.Element => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.numberedIcon, className)}
        >{Number(count) > 99 ? '99+' : count }</div>
    );
};

export default function MultipleSelect<T extends Map<string, string>>({
    helperText,
    values,
    numberedValues,
    onSetIds,
    defaultSelectedValue,
    disabledValue,
    displayAsButtons,
    classNames,
    ...rest
}: MultipleSelectProps<T>): JSX.Element {
    const classes = useStyles();
    const labelId = useRandomId();

    const [selectValue, setSelectValue] = useState<string[]>(defaultSelectedValue ?? DEFAULT_VALUE);

    useEffect(() => {
        const ids = selectValue.map(value => getMapKeyByValue(values, value));

        onSetIds(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectValue]);

    const handleChange = (event: SelectChangeEvent<typeof selectValue>): void => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        const result = typeof value === 'string' ? value.split(',') : value;

        setSelectValue(result);
    };

    const handleButtonClick = (value: string): void => {
        const newValues = [...selectValue];

        newValues.includes(value) ? newValues.splice(newValues.indexOf(value), 1) : newValues.push(value);
        setSelectValue(newValues);
    };

    const renderValue = (selected: string[]): ReactNode => selected.join(', ');

    const numberedValuesObject: { [key: string]: string } = {};

    numberedValues?.forEach((value, key) => {
        numberedValuesObject[key] = value;
    });

    if (displayAsButtons) {
        return (
            <Box marginBottom="2rem" className={classNames?.buttonsWrapper}>
                {Array.from(values).map((item, index) => (
                    <PrimaryButton
                        key={item[0]}
                        onClick={() => handleButtonClick(item[1])}
                        disabled={disabledValue?.includes(item[1]) ?? rest.disabled}
                        className={clsx(classes.button, {
                            [classes.firstButton]: index === 0,
                            [classes.lastButton]: index === values.size - 1,
                            [classes.buttonSelected]: selectValue.includes(item[1]),
                        })}
                        iconRight={ numberedValuesObject[item[0]] ? (
                            <NumberedIcon
                                count={numberedValuesObject[item[0]]}
                                className={classNames?.numberedIcon}
                            />
                        ) : undefined}
                    >
                        {item[1]}
                    </PrimaryButton>
                ))}
            </Box>
        );
    }

    return (
        <FormControl fullWidth variant="filled" className={classNames?.componentWrapper}>
            <InputLabel id={labelId} error={rest.error} required={rest.required}>
                {rest.label}
            </InputLabel>
            <Select
                {...rest}
                className={classNames?.selectWrapper}
                classes={classNames?.selectClasses}
                defaultValue={DEFAULT_VALUE}
                variant="filled"
                labelId={labelId}
                multiple
                value={selectValue}
                renderValue={renderValue}
                onChange={handleChange}
                fullWidth
                displayEmpty
            >
                {Array.from(values).map(item => (
                    <MenuItem key={item[0]} className={classNames?.item} value={item[1]} disabled={disabledValue?.includes(item[1])}>
                        <Checkbox className={classNames?.checkbox} checked={selectValue.includes(item[1])} />
                        <ListItemText className={classNames?.text} primary={item[1]} />
                    </MenuItem>
                ))}
            </Select>

            <FormHelperText error={rest.error}>{helperText}</FormHelperText>
        </FormControl>
    );
}
