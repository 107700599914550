/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { AppBar, Hidden, Link, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import logo from '../../images/logoLightBackground.svg';

const useStyles = makeStyles(theme => ({
    appBar: { backgroundColor: theme.palette.common.white },
    logoContainer: {
        margin: '0 auto',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            margin: 0,
            flexGrow: 1,
        },
    },
    logoLink: {
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    logo: {
        height: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

interface TopBarProps {
    linkPrefix?: string;
}

const TopBar: FunctionComponent<React.PropsWithChildren<TopBarProps>> = ({
    linkPrefix,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <div className={classes.logoContainer}>
                    <a href={'/'} className={classes.logoLink}>
                        <img
                            src={logo}
                            alt={t('signedOutLayout.alt.logo')}
                            className={classes.logo}
                        />
                    </a>
                </div>

                <Hidden smDown>
                    {linkPrefix !== undefined
                    && <Typography color="textPrimary" paddingRight="0.25rem">
                        {linkPrefix}
                    </Typography>
                    }
                    <Link
                        variant="body1"
                        href={config.marketingWebsiteUrl}
                        color="textPrimary"
                    >
                        {t('signedOutLayout.marketingWebsiteLink')}
                    </Link>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
