import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useModalStyles = makeStyles(theme => createStyles({
    root: { display: 'flex' },
    content: {
        padding: theme.spacing(4),
        '& h2': {
            marginTop: 0,
            lineHeight: '1.5em',
            [theme.breakpoints.up('sm')]: { lineHeight: '29px' },
        },
        '& p': { margin: 0 },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        '& [class*="MuiSvgIcon-root"]': {
            width: '0.75em',
            height: '0.75em',
        },
    },
    closeButtonMobile: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(4),
        color: theme.palette.grey[500],
        '& [class*="MuiSvgIcon-root"]': {
            width: '0.75em',
            height: '0.75em',
        },
    },
    paper: {
        minWidth: '100vw',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useModalStyles;
