/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';
import { SecondaryButton } from './buttons';

import PrimaryButton from './buttons/PrimaryButton';
import Modal from './Modal';

const useStyles = makeStyles({
    buttonContainer: { marginTop: '1rem' },
    buttonsFullWidthContainer: {
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        '& > span': {
            width: '100%',

            '& > button': {
                width: '100%',
            },
        },
    },
    header: { marginBottom: '1rem' },
    heading: { margin: 0 },
    primaryButton: { marginRight: '.5rem' },
});

export interface MessageDialogProps {
    title: string;
    onClose: () => void;
    onConfirm: () => void;
    closeButtonLabel?: string;
    confirmButtonLabel?: string;
    isConfirmButtonDisplayed?: boolean;
    closeButtonType?: 'primary' | 'secondary';
    confirmButtonClassName?: string;
    closeButtonClassName?: string;
    areButtonsFullWidth?: boolean;
}

const MessageDialog: FunctionComponent<React.PropsWithChildren<MessageDialogProps>> = ({
    title,
    onClose,
    onConfirm,
    closeButtonLabel,
    confirmButtonLabel,
    isConfirmButtonDisplayed,
    closeButtonType = 'primary',
    confirmButtonClassName,
    closeButtonClassName,
    areButtonsFullWidth = false,
    children,
}) => {
    const modalClasses = useStyles();

    return (
        <Modal open={true} onClose={onClose}>
            <div className={modalClasses.header}>
                <Typography variant="h2" component="h2" className={modalClasses.heading}>
                    {title}
                </Typography>
            </div>
            <div>{children}</div>
            <div
                className={areButtonsFullWidth
                    ? modalClasses.buttonsFullWidthContainer
                    : modalClasses.buttonContainer}
            >
                {isConfirmButtonDisplayed && (
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton onClick={onConfirm} className={confirmButtonClassName}>
                            {confirmButtonLabel ?? 'Confirm'}
                        </PrimaryButton>
                    </span>
                )}
                <span className={modalClasses.primaryButton}>
                    {closeButtonType === 'primary' ? (
                        <PrimaryButton onClick={onClose} className={closeButtonClassName}>
                            {closeButtonLabel ?? 'Close'}
                        </PrimaryButton>
                    ) : (
                        <SecondaryButton onClick={onClose} className={closeButtonClassName}>
                            {closeButtonLabel ?? 'Close'}
                        </SecondaryButton>
                    )}
                </span>
            </div>
        </Modal>
    );
};

export default MessageDialog;
