/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import formatISO from 'date-fns/formatISO';
import React, { FunctionComponent } from 'react';
import TextField from './TextField';

export type ClockType = 'hours' | 'minutes' | 'seconds';

export interface TimeFieldProps extends Partial<TimePickerProps<Date, Date>> {
    value: Date | null;
    onChange: (newValue: Date | null) => void;
    required?: boolean;
    helperText?: string;
    ampm?: boolean;
    format?: string;
    utc?: boolean;
    error?: boolean;
    noHelperTextSpace?: boolean;
    disableKeyboard?: boolean;
    placeholder?: string;
}

const TimeField: FunctionComponent<React.PropsWithChildren<TimeFieldProps>> = ({
    onChange,
    value,
    required,
    helperText,
    ampm,
    format,
    utc,
    error,
    shouldDisableTime,
    noHelperTextSpace,
    disableKeyboard,
    placeholder,
    ...rest
}) => (
    <TimePicker
        {...rest}
        value={value}
        onChange={newValue => {
            const result = (() => {
                if (newValue === null) {
                    return null;
                }

                return utc ? new Date(formatISO(newValue)) : newValue;
            })();

            if (result) {
                onChange(result);
            }
        }}
        renderInput={props => (
            <TextField
                {...props}
                label={utc ? `${rest.label} (UTC)` : rest.label}
                required={required}
                helperText={helperText ?? (noHelperTextSpace ? '' : ' ')}
                error={error}
                variant="filled"
                onKeyDown={event => (disableKeyboard ? event.preventDefault() : { ...props.onKeyDown })}
                inputProps={
                    {
                        ...props.inputProps,
                        placeholder,
                    }
                }
            />
        )}
        className={rest.className}
        ampm={ampm ?? false}
        inputFormat={format ?? 'HH:mm'}
        shouldDisableTime={shouldDisableTime}
    />
);

export default TimeField;
