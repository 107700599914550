import { Box, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import repeat from '../helpers/repeat';

export interface TableSkeletonProps {
    columns: number;
}

const useStyles = makeStyles(() => createStyles({
    head: {
        marginBottom: '1rem',
    },
    columnHeading: {
        borderRadius: 4,
        height: '1.7rem',
    },
    body: {
        borderRadius: 4,
        height: 400,
        width: '100%',
    },
    skeleton: {
        backgroundColor: '#EEF3F9',
    },
}));

const TableSkeleton: FunctionComponent<React.PropsWithChildren<TableSkeletonProps>> = (
    { columns: columnCount },
) => {
    const classes = useStyles();

    return (
        <div>
            <Grid container alignItems="center" spacing={4} className={classes.head}>
                {
                    repeat(columnCount, iteration => (
                        <Grid key={iteration} item xs>
                            <Box>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    className={clsx(
                                        classes.columnHeading,
                                        classes.skeleton,
                                    )}
                                />
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>

            <Skeleton
                animation="wave"
                variant="rectangular"
                className={clsx(classes.body, classes.skeleton)}
            />
        </div>
    );
};

export default TableSkeleton;
