import { StepLabel as MuiStepLabel, StepLabelProps, styled } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import StepIcon from './StepIcon';

const StyledStepLabel = styled(MuiStepLabel)({
    '& .MuiStepLabel-active': { color: 'rgba(0, 0, 0, 0.54)' },
});

interface StepLabelInterface {
    children: StepLabelProps['children'];

    // TODO: Remove after demo
    onClick?: () => void;
    onContextMenu?: StepLabelProps['onContextMenu'];
}

const StepLabel: FC<PropsWithChildren<StepLabelInterface>> = ({
    children,

    // Note: Other props will be provided by the Step parent component
    ...otherProps
}) => (
    <StyledStepLabel StepIconComponent={StepIcon} {...otherProps}>
        {children}
    </StyledStepLabel>
);

export default StepLabel;
