import { Tabs as MuiTabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, PropsWithChildren } from 'react';

const useStyles = makeStyles(theme => ({
    root: { borderBottom: `1px solid ${theme.palette.grey[500]}` },
    indicator: {
        backgroundColor: theme.palette.primary.main,
        padding: `0 ${theme.spacing(3)}`,
    },
}));

export interface NumberedTabsProps {
    value: number;
    className?: string;
}

const NumberedTabs: FC<PropsWithChildren<NumberedTabsProps>> = ({
    value,
    children,
    className,
}) => {
    const classes = useStyles();

    return (
        <MuiTabs
            classes={{
                indicator: classes.indicator,
                root: classes.root,
            }}
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            className={className}
        >
            {children}
        </MuiTabs>
    );
};

export default NumberedTabs;
