import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Button } from '@mui/material';
import React from 'react';

const SkipToEndButton = (
    { disabled, onClick }: {
        disabled: boolean;
        onClick: () => void;
    },
): JSX.Element => (
    <Button
        onClick={onClick}
        disabled={disabled}
    >
        <SkipNextIcon fontSize="small"/>
    </Button>
);

export default SkipToEndButton;
