import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Snackbar } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import React, { FunctionComponent } from 'react';

export type Severity = AlertColor;

export interface NotificationProps {
    severity: Severity;
    onClose: () => void;
}

const Notification: FunctionComponent<React.PropsWithChildren<NotificationProps>> = (
    { severity, onClose, children },
) => (
    <Snackbar
        open={true}
        autoHideDuration={5000}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        disableWindowBlurListener={true}
        onClose={(_event, reason) => {
            if (reason !== 'clickaway') {
                onClose();
            }
        }}
    >
        <Alert
            variant="filled"
            iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }}
            severity={severity}
            onClose={onClose}
        >
            {children}
        </Alert>
    </Snackbar>
);

export default Notification;
