import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import logo from './images/logoLightBackground.svg';

const useStyles = makeStyles({
    container: { maxWidth: '200px' },
    logo: { display: 'block' },
    progressBar: { marginTop: '1.5rem' },
});

type PageLoaderType = FunctionComponent<React.PropsWithChildren<{ className?: string }>>;

const PageLoader: PageLoaderType = ({ className }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.container, className)}>
            <img src={logo} alt="Loading Get-E portal" className={classes.logo} />

            <LinearProgress className={classes.progressBar} />
        </div>
    );
};

export default PageLoader;
