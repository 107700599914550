import {
    Link as MuiLink, LinkProps as MuiLinkProps,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps extends MuiLinkProps {
    to: string;
    replace?: boolean;
}

const Link: FunctionComponent<React.PropsWithChildren<LinkProps>>
    = props => <MuiLink {...props} component={RouterLink} />;

export default Link;
