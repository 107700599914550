import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { FunctionComponent } from 'react';
import ThemeProvider from './ThemeProvider';

const ComponentsProvider: FunctionComponent<React.PropsWithChildren<{
    children: JSX.Element;
    seed?: string;
}>> = ({ children, seed }) => (
    <ThemeProvider seed={seed}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {children}
        </LocalizationProvider>
    </ThemeProvider>
);

export default ComponentsProvider;
