import { Button, Menu as MaterialMenu } from '@mui/material';
import React, { FunctionComponent, useRef } from 'react';
import useRandomId from '../helpers/useRandomId';
import menuButtonStyles from './MenuButton.styles';

export interface MenuButtonProps {
    open: boolean;
    onClick: () => void;
    onClose: () => void;
    menuItems: JSX.Element[] | JSX.Element;
    className?: string;
    alignItem?: 'center' | 'left' | 'right';
}

const MenuButton: FunctionComponent<React.PropsWithChildren<MenuButtonProps>> = ({
    open,
    onClick,
    onClose,
    menuItems,
    children,
    className,
    alignItem = 'right',
}) => {
    const id = useRandomId();
    const classes = menuButtonStyles();
    const buttonRef = useRef(null);
    const anchorEl = open ? buttonRef.current : null;

    return (
        <div className={classes.menuContainer}>
            <Button
                aria-controls={id}
                aria-haspopup="true"
                onClick={onClick}
                ref={buttonRef}
                className={className}
            >
                {children}
            </Button>

            <MaterialMenu
                id={id}
                className={classes.menu}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: alignItem,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {menuItems}
            </MaterialMenu>
        </div>
    );
};

export default MenuButton;
