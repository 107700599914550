import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Chip, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, PropsWithChildren, useState } from 'react';
import { PrimaryButton, TertiaryButton } from '../buttons';
import useId from '../helpers/useRandomId';

export interface PopoverFilterChipProps {
    label: string;
    className?: string;
    onSave: () => Promise<void> | void;
    onClear: () => Promise<void> | void;
}

const useStyles = makeStyles({
    popoverContent: {
        padding: '1rem',
    },
    buttons: {
        marginTop: '.5rem',
    },
    submitButton: {
        marginRight: '1rem',
    },
});

const PopoverFilterChip: FC<PropsWithChildren<PopoverFilterChipProps>> = props => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const id = useId();
    const classes = useStyles();
    const [saving, setSaving] = useState(false);
    const [clearing, setClearing] = useState(false);

    return (
        <>
            <Chip
                aria-describedby={anchor ? id : undefined}
                variant="outlined"
                label={props.label}
                className={props.className}
                onClick={event => setAnchor(event.currentTarget)}
                icon={<ArrowDropDownIcon />}
            />

            <Popover
                id={id}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => {
                    if (saving || clearing) {
                        return;
                    }

                    setAnchor(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.popoverContent}>
                    {props.children}

                    <div className={classes.buttons}>
                        <PrimaryButton
                            onClick={async () => {
                                setSaving(true);
                                await props.onSave();
                                setSaving(false);
                                setAnchor(null);
                            }}
                            loading={saving}
                            disabled={clearing}
                            small
                            className={classes.submitButton}
                        >
                            {/* TODO: Translate */}
                            Save
                        </PrimaryButton>

                        <TertiaryButton
                            onClick={async () => {
                                setClearing(true);
                                await props.onClear();
                                setClearing(false);
                                setAnchor(null);
                            }}
                            loading={clearing}
                            disabled={saving}
                        >
                            Clear
                        </TertiaryButton>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default PopoverFilterChip;
