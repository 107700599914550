import { CircularProgress, MenuItem, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import useLoaderDebounce from '../helpers/useLoaderDebounce';

export interface MenuButtonItemProps {
    onClick: () => void;
    dangerous?: boolean;
    children: string;
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    dangerous: { color: theme.palette.error.main },
    spinner: { marginLeft: '.5rem' },
}));

const MenuButtonItem = forwardRef<HTMLLIElement, MenuButtonItemProps>(
    function MenuButtonItem(
        { onClick, dangerous, children, loading, ...otherProps },
        ref,
    ) {
        const classes = useStyles();
        const showSpinner = useLoaderDebounce(loading ?? false);

        return (
            <MenuItem
                onClick={onClick}
                className={clsx({ [classes.dangerous]: dangerous })}
                disabled={loading}
                ref={ref}
                {...otherProps}
            >
                {children}

                {
                    showSpinner
                        ? (
                            <CircularProgress
                                className={classes.spinner}
                                size={15}
                                color="inherit"
                                thickness={4}
                            />
                        )
                        : null
                }
            </MenuItem>
        );
    },
);

export default MenuButtonItem;
