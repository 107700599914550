import { Tab as MaterialTab, TabProps as MuiTabProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { FunctionComponent } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

const StyledTab = withStyles((theme: Theme) => createStyles({
    root: {
        textTransform: 'none',
        minWidth: 'auto',
        minHeight: '50px',
        padding: `0 ${theme.spacing(2)}`,
        overflow: 'visible',
        color: theme.palette.grey[200],
        '&:hover': {
            color: theme.palette.grey[400],
            opacity: 1,
        },
        '&$selected': { color: theme.palette.primary.main },
        '&:focus': { color: theme.palette.primary.main },
    },
    selected: {},
    wrapper: {
        justifyItems: 'baseline',
        alignItems: 'flex-start',
        fontSize: '1em',
    },
}))((props: MuiTabProps | MuiTabProps & LinkProps) => <MaterialTab {...props} />);

export interface TabProps {
    label: string;
    index: number;
    selectedIndex: number;
    url?: string;
}

const Tab: FunctionComponent<React.PropsWithChildren<TabProps>> = (
    { url, label, index, selectedIndex, ...otherProps },
) => {
    const linkProps = typeof url === 'undefined'
        ? null
        : {
            to: url,
            component: RouterLink,
        };

    return (
        <StyledTab
            disableRipple
            label={label}
            selected={selectedIndex === index}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            {...linkProps}
            {...otherProps}
        />
    );
};

export default Tab;
