/* eslint-disable @typescript-eslint/naming-convention */
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    DataGridProps,
} from '@mui/x-data-grid';
import clsx from 'clsx';
import React from 'react';

import { emptyAirportIcon, Heading } from '..';

import useDataGridStyles from './DataGridTable.styles';

interface CustomProps {
    noRows?: string;
    enableExport?: boolean;
    noResultImage?: string;
    noRowsClassName?: string;
}

function CustomNoRowsOverlay(props: CustomProps): JSX.Element {
    const classes = useDataGridStyles();
    const { noRows, noResultImage, noRowsClassName } = props;

    return (
        <div className={clsx(classes.dataGridNoRows, noRowsClassName ?? '')}>
            <img
                src={noResultImage ?? emptyAirportIcon}
                alt={noRows}
                className={classes.iconNoRows}
            />
            {noRows}
        </div>
    );
}

function CustomToolbar(props: CustomProps): JSX.Element {
    const { enableExport } = props;

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton
                onResize={undefined}
                nonce={undefined}
                onResizeCapture={undefined}
            />
            <GridToolbarFilterButton
                onResize={undefined}
                nonce={undefined}
                onResizeCapture={undefined}
            />
            <GridToolbarDensitySelector
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
            />
            {enableExport ? <GridToolbarExport /> : null}
        </GridToolbarContainer>
    );
}

export interface DataGridTableProps extends DataGridProps, CustomProps {
    className?: string;
    enableFilters?: boolean;
    header?: string;
    noResultImage?: string;
    noRowsClassName?: string;
    hideFooter?: boolean;
}

const DataGridTable = (props: DataGridTableProps): JSX.Element => {
    const classes = useDataGridStyles();
    const { enableExport, noRows, noResultImage, noRowsClassName } = props;

    return (
        <div className={clsx(props.className, classes.dataGrid)}>
            {props?.header?.length ? (
                <div className={classes.dataGridHeader}>
                    <Heading level={2}>{props.header}</Heading>
                </div>
            ) : null}
            <DataGrid
                components={
                    props.enableFilters
                        ? {
                            Toolbar: CustomToolbar,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }
                        : { NoRowsOverlay: CustomNoRowsOverlay }
                }
                componentsProps={{
                    toolbar: { enableExport },
                    noRowsOverlay: { noRows,
                        noResultImage,
                        noRowsClassName },
                }}
                pageSize={props.pageSize}
                rowsPerPageOptions={props.rowsPerPageOptions}
                disableSelectionOnClick
                columns={props.columns}
                rows={props.rows}
                autoHeight={props.autoHeight}
                loading={props.loading}
                onRowClick={props.onRowClick}
                initialState={props.initialState}
                getRowHeight={props.getRowHeight}
                hideFooter={props.hideFooter}
            />
        </div>
    );
};

export default DataGridTable;
