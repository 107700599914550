import {
    TableBody as MuiTableBody,
    TableBodyProps as MuiTableBodyProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

export type TableBodyProps = MuiTableBodyProps & { loading?: boolean };

const useStyles = makeStyles({
    root: {
        transition: 'opacity .5s',
    },
    loading: {
        opacity: 0.4,
    },
});

const TableBody: FC<PropsWithChildren<TableBodyProps>> = ({ loading, ...otherProps }) => {
    const classes = useStyles();

    return (
        <MuiTableBody
            {...otherProps}
            className={clsx(otherProps.className, classes.root, {
                [classes.loading]: loading,
            })}
        />
    );
};

export default TableBody;
