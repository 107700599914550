import { Tabs as MuiTabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, PropsWithChildren } from 'react';

const useStyles = makeStyles(theme => ({
    root: { borderBottom: `1px solid ${theme.palette.grey[500]}` },
    indicator: { backgroundColor: theme.palette.primary.main },
}));

export interface TabsProps {
    value: number;
    className?: string;
    onChange?: (index: number) => void;
}

const Tabs: FC<PropsWithChildren<TabsProps>> = ({
    value,
    children,
    className,
    onChange,
}) => {
    const classes = useStyles();

    return (
        <MuiTabs
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            classes={{
                root: classes.root,
                indicator: classes.indicator,
            }}
            className={className}
            onChange={(_event, newValue) => onChange?.(newValue as number)}
        >
            {children}
        </MuiTabs>
    );
};

export default Tabs;
