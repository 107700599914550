import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
    Collapse,
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, PropsWithChildren, ReactElement, useState } from 'react';
import getValue from '../helpers/getValue';

const useStyles = makeStyles(theme => createStyles({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid #e0e0e0',
    },
    title: {
        flex: '1 1 100%',
        margin: 0,
    },
    searchField: {
        maxWidth: '25ch',
        marginRight: '.5rem',
    },
    filterRow: {
        borderBottom: '1px solid #e0e0e0',
        padding: '.5rem',
    },
    chipWrapper: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '.25rem',
    },
}));

type TableHeaderProps = PropsWithChildren<{
    title: string;
    onSearchChange?: (value: string) => void;
    filters?: {
        enabled: boolean;
        onEnable: () => void;
        onDisable: () => void;
        chips: ReactElement[];
    };
}>;

const TableHeader: FC<TableHeaderProps> = ({ title, onSearchChange, filters }) => {
    const classes = useStyles();

    // TODO: Translate

    return (
        <>
            <Toolbar className={classes.root}>
                <Typography className={classes.title} variant="h2" component="div">
                    {title}
                </Typography>

                {getValue(() => {
                    if (!filters) {
                        return null;
                    }

                    return filters.enabled ? (
                        <Tooltip title="Hide filters">
                            <IconButton
                                aria-label="Hide filters"
                                onClick={() => filters.onDisable()}
                                color="primary"
                                size="large"
                            >
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Show filters">
                            <IconButton
                                aria-label="Show filters"
                                onClick={() => filters.onEnable()}
                                size="large"
                            >
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    );
                })}
                {onSearchChange ? <HeaderSearch onSearchChange={onSearchChange} /> : null}
                {}
            </Toolbar>

            {filters ? (
                <Collapse in={filters.enabled}>
                    <div className={classes.filterRow}>
                        {filters.chips.map((chip, index) => (
                            <div key={index} className={classes.chipWrapper}>
                                {chip}
                            </div>
                        ))}
                    </div>
                </Collapse>
            ) : null}
        </>
    );
};

type HeaderSearchProps = PropsWithChildren<{ onSearchChange: (value: string) => void }>;

const HeaderSearch: FC<HeaderSearchProps> = ({ onSearchChange }) => {
    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const classes = useStyles();

    return searchBarOpen ? (
        <>
            <TextField
                variant="outlined"
                label="Search"
                fullWidth
                size="small"
                value={searchValue}
                onChange={event => {
                    const newValue = event.target.value;

                    setSearchValue(newValue);
                    onSearchChange(newValue);
                }}
                className={classes.searchField}
                autoFocus
                onBlur={() => {
                    if (searchValue.trim() === '') {
                        setSearchBarOpen(false);
                    }

                    onSearchChange(searchValue);
                }}
            />

            <Tooltip title="Close search">
                <IconButton
                    aria-label="Close search"
                    onClick={() => {
                        setSearchBarOpen(false);
                        setSearchValue('');
                        onSearchChange('');
                    }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </>
    ) : (
        <Tooltip title="Search">
            <IconButton
                aria-label="Search in list"
                onClick={() => setSearchBarOpen(true)}
                size="large"
            >
                <SearchIcon />
            </IconButton>
        </Tooltip>
    );
};

export default TableHeader;
