import React from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import logo from '../../images/logoDarkBackground.svg';
import icon from '../../images/logoIcon.svg';
import navigationLogoStyles from './SideBarLogo.styles';

interface Props {
    closed: boolean;
}

const SideBarLogo = ({ closed }: Props): JSX.Element => {
    const currentYear = new Date().getFullYear();
    const classes = navigationLogoStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.companyInformationContainer}>
            {
                closed
                    ? (
                        <img
                            src={icon}
                            className={classes.smallLogo}
                            alt={t('copyright.companyName')}
                        />
                    )
                    : (
                        <>
                            <img
                                src={logo}
                                className={classes.largeLogo}
                                alt= {t('copyright.companyName')}
                            />
                            <p className={classes.companyInformation}>
                                {t('copyright.companyName')}
                            </p>
                            <p className={classes.companyInformation}>
                        © {t('copyright.yearFormat', {
                                    startYear: config.copyrightStartYear,
                                    currentYear,
                                })}
                            </p>
                        </>
                    )
            }
        </div>
    );
};

export default SideBarLogo;
