import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useMenuButtonStyles = makeStyles(theme => createStyles({
    menuContainer: {
        ...theme.typography.button,
        display: 'inline-block',
        textAlign: 'right',
        '& [class*="MuiButton-label"]': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '& [class*="MuiButton-text"]': {
            padding: '0.5em 0',
            minWidth: 'unset',
        },
        textTransform: 'none',
    },
    menu: {
        '& [class*="MuiListItem-root"]': {
            '&:hover': { backgroundColor: theme.palette.grey[100] },
        },
        '& [class*="MuiListItem-gutters"]': { padding: theme.spacing(1.5, 2) },
        '& [class*="MuiList-padding"]': { padding: 0 },
        '& [class*="MuiListItemText-root"]': { margin: 0 },
    },
    userMenu: { padding: `${theme.spacing(1, 2)} !important` },
}));

export default useMenuButtonStyles;
