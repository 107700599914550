import { AppBar, Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import BackToStartButton from './pagination/BackToStartButton';
import NextButton from './pagination/NextButton';
import PreviousButton from './pagination/PreviousButton';
import SkipToEndButton from './pagination/SkipToEndButton';

export interface TableFooterProps {
    onNextClick: () => void;
    onPreviousClick: () => void;
    onBackToStartClick: () => void;
    onSkipToEndClick: () => void;
    hasNext: boolean;
    hasPrevious: boolean;
    count: {
        total: number;
        currentPage: number;
    };
    loading: boolean;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        transition: 'opacity .5s',
    },
    controls: {
        margin: '.5rem',
    },
    mobileAppBar: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        color: theme.palette.common.black,
        padding: theme.spacing(0),
        top: 'auto',
        '& [class*="MuiButton-root"]': {
            minWidth: '40px',
            padding: '5px 13px',
        },
        '& [class*="MuiToolbar-gutters"]': { paddingRight: 0 },
    },
    count: {
        marginLeft: '1rem',
        color: 'grey',
    },
    loading: {
        opacity: 0.4,
    },
}));

const TableFooter: FunctionComponent<React.PropsWithChildren<TableFooterProps>> = ({
    onNextClick,
    onPreviousClick,
    hasNext,
    hasPrevious,
    onBackToStartClick,
    onSkipToEndClick,
    count,
    loading,
}) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const content = (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={clsx(classes.root, { [classes.loading]: loading })}
        >
            <Grid item>
                {
                    typeof count === 'undefined'
                        ? null

                        // TODO: Translate
                        : (
                            <span className={classes.count}>
                                {count.currentPage} of {count.total}
                            </span>
                        )
                }
            </Grid>

            <Grid item>
                <nav
                    role="navigation"
                    aria-label="Pagination"
                    className={classes.controls}
                >
                    <BackToStartButton
                        onClick={onBackToStartClick}
                        disabled={!hasPrevious || Boolean(loading)}
                    />

                    <PreviousButton
                        onClick={onPreviousClick}
                        disabled={!hasPrevious || Boolean(loading)}
                    />

                    <NextButton
                        onClick={onNextClick}
                        disabled={!hasNext || Boolean(loading)}
                    />

                    <SkipToEndButton
                        onClick={onSkipToEndClick}
                        disabled={!hasNext || Boolean(loading)}
                    />
                </nav>
            </Grid>
        </Grid>
    );

    if (mobileView) {
        return (
            <AppBar position="fixed" className={classes.mobileAppBar}>
                <Toolbar>
                    {content}
                </Toolbar>
            </AppBar>
        );
    }

    return content;
};

export default TableFooter;
