/* eslint-disable no-nested-ternary */
import { Tab as MaterialTab, TabProps, Theme, Skeleton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React, { FunctionComponent } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

type StyledTabProps = TabProps & LinkProps;

const StyledTab = withStyles((theme: Theme) => createStyles({
    root: {
        textTransform: 'none',
        minWidth: 'auto',
        minHeight: '50px',
        padding: `0 ${theme.spacing(2)}`,
        marginRight: theme.spacing(6),
        overflow: 'visible',
        color: theme.palette.grey[200],
        '&:hover': {
            color: theme.palette.grey[400],
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
            '& > span > span': {
                color: 'white',
                backgroundColor: theme.palette.primary.main,
            },
        },
        '&:focus': { color: theme.palette.primary.main },
    },
    selected: {},
    wrapper: {
        justifyItems: 'baseline',
        alignItems: 'flex-start',
        fontSize: '1em',
    },
}))((props: StyledTabProps) => <MaterialTab disableRipple {...props} />);

export interface NumberedTabProps {
    number: number | null;
    loading?: boolean;
    url: string;
    label: string;
    index: number;
    selectedIndex: number;
    isHeighterNumberHidden?: boolean;
}

const useStyles = makeStyles(theme => createStyles({
    counter: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[200],
        width: '2em',
        padding: '2px 0',
        borderRadius: '100%',
        textAlign: 'center',
        position: 'absolute',
        right: '-32px',
    },
}));

const NumberedTab: FunctionComponent<React.PropsWithChildren<NumberedTabProps>> = (
    { number, loading, url, label, index, selectedIndex, isHeighterNumberHidden },
) => {
    const classes = useStyles();

    const icon = (() => {
        if (loading) {
            return (
                <Skeleton
                    variant="circular"
                    width="36x"
                    height="29px"
                    className={classes.counter}
                />
            );
        }

        return (
            <span className={classes.counter}>
                {isHeighterNumberHidden ? number && number > 100 ? '99+' : number ?? '?' : number}
            </span>
        );
    })();

    return (
        <StyledTab
            disableRipple
            icon={icon}
            component={RouterLink}
            to={url}
            label={label}
            selected={selectedIndex === index}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
        />
    );
};

export default NumberedTab;
