import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useTypographyStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        textAlign: 'right',
    },
    errorContainer: {
        width: '100%',
        display: 'block',
        textAlign: 'left',
        minHeight: '1em',
        margin: '0.75em 0',
    },
    errorTheme: {
        color: theme.palette.error.main,
        display: 'block',
    },
    errorMessageMargin: { margin: '0 0 2em' },
    successTheme: { color: `${theme.palette.success.main} !important` },
    dialogueTitle: { textAlign: 'left' },
    dialogueList: { padding: theme.spacing(0, 2) },
    body1Override: {
        fontSize: '1em',
        fontWeight: 'bold',
    },
    inlineLink: {
        marginLeft: '0.5em',
        fontSize: '0.75em',
        color: theme.palette.grey[300],
        '&:hover': { color: theme.palette.grey[200] },
    },
    black: { color: theme.palette.common.black },
    grey: { color: theme.palette.grey[200] },
    whiteLink: {
        color: `${theme.palette.common.white} !important`,
        textAlign: 'center',
        '&:hover': { color: theme.palette.grey[300] },
    },
    subtitle: {
        fontSize: '1em',
        color: theme.palette.grey[300],
    },
    subtitle1Override: {
        fontSize: '0.75em',
        color: theme.palette.grey[200],
    },
    subtitle2Override: {
        fontSize: '0.85em',
        margin: '1em 0 2em 0',
    },
    body1: { color: `${theme.palette.common.black} !important` },
    capitalize: { textTransform: 'uppercase' },
    mobileTitle: { [theme.breakpoints.down('sm')]: { fontWeight: 'bold' } },
}));

export default useTypographyStyles;
