import { Fade, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';
import PageLoader from './PageLoader';

const useStyles = makeStyles({
    // The 200px below is based on the top bar and content padding
    loaderContainer: { minHeight: 'calc(100vh - 200px)' },
    loaderItem: { width: '100%' },
    loader: {
        width: '60%',
        margin: '0 auto',
    },
});

const LoadingPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const classes = useStyles();

    return (
        <Fade in>
            <div>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.loaderContainer}
                >
                    <Grid item xs={12} className={classes.loaderItem}>
                        <PageLoader className={classes.loader} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
};

export default LoadingPage;
