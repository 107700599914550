import { ListItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
    icon: JSX.Element;
    label: string;
    selected?: boolean;
    href: string;
    onClick?: () => void;
}

const MenuListItem: FunctionComponent<React.PropsWithChildren<Props>> = (
    { icon, label, selected, href, onClick },
) => (
    <div onClick={onClick}>
        <ListItem
            button
            selected={selected}
            component={NavLink}
            to={href}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </ListItem>
    </div>
);

export default MenuListItem;
