import Check from '@mui/icons-material/Check';
import { StepIconProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        // TODO: Move to theme
        color: '#388e3c',
        zIndex: 1,
        fontSize: 18,
    },
});

const StepIcon: FunctionComponent<React.PropsWithChildren<StepIconProps>> = props => {
    const classes = useStyles();
    const { completed } = props;

    return (
        <div className={classes.root}>
            {
                completed
                    ? <Check className={classes.completed} />
                    : <div className={classes.circle} />
            }
        </div>
    );
};

export default StepIcon;
