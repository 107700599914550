import {
    TableContainer as MuiTableContainer,
    TableContainerProps as MuiTableContainerProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

type TableContainerProps = MuiTableContainerProps & {noFooter?: boolean};

const useStyles = makeStyles({
    noFooter: {
        borderBottom: 'none',
    },
});

const TableContainer: FunctionComponent<React.PropsWithChildren<TableContainerProps>> = (
    { noFooter, ...otherProps },
) => {
    const classes = useStyles();

    return (
        <MuiTableContainer
            {...otherProps}
            className={
                clsx(
                    otherProps.className,
                    { [classes.noFooter]: noFooter },
                )
            }
        />
    );
};

export default TableContainer;
