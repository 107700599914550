import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

export interface ArrowButtonProps {
    onClick: () => void;
    children: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.black,
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        textAlign: 'left',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#000',
        },
    },
}));

const ArrowButton: FunctionComponent<React.PropsWithChildren<ArrowButtonProps>> = ({
    onClick,
    children,
}) => {
    const classes = useStyles();

    return (
        <Button
            onClick={onClick}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={classes.root}
        >
            {children}

            <ArrowForwardIcon color="secondary" />
        </Button>
    );
};

export default ArrowButton;
