import makeStyles from '@mui/styles/makeStyles';
import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        textDecoration: 'none',
        color: '#828282',
        fontWeight: 600,
        fontSize: '1rem',
    },
});

export interface TertiaryButtonLinkProps {
    to: string;
}

const TertiaryButtonLink: FC<PropsWithChildren<TertiaryButtonLinkProps>> = ({
    children,
    to,
}) => {
    const classes = useStyles();

    return (
        <Link to={to} className={classes.root}>
            {children}
        </Link>
    );
};

export default TertiaryButtonLink;
