import {
    Collapse,
    LinearProgress,
    TableCell,
    TableHead as MuiTableHead,
    TableHeadProps as MuiTableHeadProps,
    TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';
import useLoaderDebounce from '../helpers/useLoaderDebounce';

export type TableHeadProps = MuiTableHeadProps
& ({loading: true; columns: number} | {loading?: false; columns?: number});

const useStyles = makeStyles({
    loaderCell: {
        padding: 0,
        borderBottom: 0,
    },
});

const TableHead: FunctionComponent<React.PropsWithChildren<TableHeadProps>> = (
    { loading, columns, ...otherProps },
) => {
    const classes = useStyles();
    const showLoader = useLoaderDebounce(loading ?? false);

    return (
        <MuiTableHead {...otherProps}>
            {otherProps.children}

            <TableRow>
                <TableCell colSpan={columns} className={classes.loaderCell}>
                    <Collapse in={showLoader} timeout={100}>
                        <LinearProgress/>
                    </Collapse>
                </TableCell>
            </TableRow>
        </MuiTableHead>
    );
};

export default TableHead;
